/*
********************************************************************************
Functions related to PDF generation using jsPDF and jsPDF-AutoTable libraries
********************************************************************************
*/

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Browser } from '@capacitor/browser';

import { consoleMsg } from './utility.js';

// TO DO: Replace with data retrieved from NAVS API

let fakeData = {
  driver: 'Connor Castro',
  vehicle: '2024 BMW 3 Series',
  manufacturer: 'BMW',
};

export async function generateInspectionPDF(details) {
  // Define variables to store page data for insertion into AutoTable

  const page2 = await store.get('page2');
  const page3 = await store.get('page3');
  const page4 = await store.get('page4');
  const page5 = await store.get('page5');
  const page6 = await store.get('page6');
  const page7 = await store.get('page7');
  const page8 = await store.get('page8');
  // Set line height multiplier
  const lineHeight = 1.35;

  const doc = new jsPDF({
    unit: 'mm',
    lineHeight: lineHeight,
  });

  const margin = 24;
  const fontSize = 10;
  const pageWidth = Math.round(doc.internal.pageSize.width);
  const pageHeight = Math.round(doc.internal.pageSize.height);
  const maxLineWidth = pageWidth - 2 * margin;
  const charSpacing = 0; // Default = 0
  const sectionBreak = 5; // Variable to add spacing between sections
  const pages = ['page2', 'page3', 'page4', 'page5', 'page6', 'page7', 'page8'];

  // Set initial Y position and offset
  let yPosition = 20;
  let yOffset = 0;

  // Set metadata
  doc.setProperties({
    title: 'Vehicle Inspection Summary',
    subject: 'Summary of vehicle inspection',
    author: 'Page One Automotive',
    keywords: 'inspection, vehicle, summary, ' + fakeData.manufacturer,
    creator: 'jsPDF',
    custom: {
      driver: fakeData.driver,
    },
  });

  doc.setCharSpace(charSpacing);

  // Fetch images from Ionic storage
  const legendImage = await store.get('legend-image');
  const damageImage = await store.get('damage-image');
  const signatureImage = await store.get('signature');
  const logoImage = await store.get('logo-image');

  consoleMsg('Logo image:', logoImage);
  consoleMsg('Legend image:', legendImage);
  consoleMsg('Damage image:', damageImage);
  consoleMsg('Signature image:', signatureImage);

  // X = width; Y = height.  To move down the page, increase Y.  To move right, increase X.
  doc.addImage(logoImage, 'PNG', (210 - 90) / 2, yPosition, 90, 15.75);

  /* Not sure why, but need to add an additional 4 pixels to logo height to get text to offset 
	properly: investigate */
  //yPosition = 15.75;
  yPosition += 22.75;

  // Add title
  let title = 'Vehicle Inspection Summary';

  doc.setFontSize(18);
  doc.setFont('helvetica', 'bold');
  let xPosition = pageWidth / 2; // Calculate the horizontal position to center the text
  doc.text(title, xPosition, yPosition, null, null, 'center');

  // Calculate y offset: font size * line height / pixes per mm
  yOffset = calculateOffset(doc, lineHeight);
  // Add the offset to the current Y position to move down the page
  yPosition += yOffset;

  // Add summary
  doc.setFontSize(12);
  title = 'Vehicle Passed Inspection: Release Issued';
  xPosition = pageWidth / 2;
  doc.text(title, xPosition, yPosition, null, null, 'center');

  // Recalculate offset and add to current yPosition
  yOffset = calculateOffset(doc, lineHeight);
  yPosition += yOffset;

  doc.setFont('Helvetica', 'normal');

  //title = 'Inspection Details';
  title = 'Vehicle Information';
  //title = "x".repeat(90);
  xPosition = pageWidth / 2;
  doc.setFontSize(8);
  doc.setFillColor(74, 131, 192);
  doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
  doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, 'F');

  yPosition += 4;

  doc.text(title, xPosition, yPosition, null, null, 'center');

  yPosition += 4;

  // Reset text color to black
  doc.setTextColor(0);

  addDetailsTable(doc, details, margin, yPosition);

  // Get the final Y position after the table is drawn
  let finalY = doc.lastAutoTable.finalY;

  // Calculate the height of the table
  let tableHeight = finalY - yPosition;

  // Update yPosition for the next section
  yPosition = finalY + sectionBreak; // Add some padding after the table

  title = 'Exterior Inspection';
  //title = "x".repeat(90);
  xPosition = pageWidth / 2;
  doc.setFontSize(8);
  doc.setFillColor(74, 131, 192);
  doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
  doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, 'F');

  yPosition += 4;

  doc.text(title, xPosition, yPosition, null, null, 'center');

  yPosition += 4;

  // Reset text color to black
  doc.setTextColor(0);

  // Add legend & damage image

  // Recalculate offset and add to current yPosition
  yOffset = calculateOffset(doc, lineHeight);
  //yPosition += yOffset;

  // Define variables to scale image based on page margins
  let imgWidth = 180;
  //let imgHeight = 31;
  let imgHeight = 22;

  // Define variables to scale image based on page margins
  let scaleFactor = (pageWidth - 2 * margin) / imgWidth;

  if (legendImage) {
    // If yPosition + height of legend image + damage image exceeds page height, add a new page
    if (yPosition + margin + 106.6 > pageHeight) {
      doc.addPage();
      consoleMsg('Adding new page for legend image');
      yPosition = margin;
    }
    imgWidth *= scaleFactor;
    imgHeight *= scaleFactor;
    doc.addImage(legendImage, 'PNG', margin, yPosition, imgWidth, imgHeight);
    yPosition += imgHeight;
  }

  if (damageImage) {
    imgHeight = 75.6 * scaleFactor;
    doc.addImage(damageImage, 'PNG', margin, yPosition, imgWidth, imgHeight);
    //yPosition += imgHeight + sectionBreak;
    yPosition += imgHeight;
  }

  let currentPage = 'page2';
  addDataTable(doc, page2, margin, yPosition, currentPage);

  finalY = doc.lastAutoTable.finalY;
  tableHeight = finalY - yPosition;

  // Update yPosition for the next section
  yPosition = finalY + sectionBreak;

  // Fetch all damage photos
  const keys = await store.keys();
  const damagePhotos = {};
  for (const key of keys) {
    //if (key.includes("photoRow") && !key.includes("-ratio")) {
    if (key.includes('photoRow')) {
      const photoObject = await store.get(key);
      const photo = photoObject.photo;
      const ratio = photoObject.ratio;
      damagePhotos[key] = { photo, ratio };
    }
  }

  // Add damage photos
  if (yPosition + margin + 75.6 > pageHeight) {
    doc.addPage();
    consoleMsg('Adding new page for damage photos');
    yPosition = margin;
  }

  /*doc.text("Damage Photos:", margin, yPosition);
	yOffset = calculateOffset(doc, lineHeight);
	consoleMsg("Damage photos text yOffset", yOffset);
	yPosition += yOffset;*/
  title = 'Damage Photos';
  //title = "x".repeat(90);
  xPosition = pageWidth / 2;
  doc.setFontSize(8);
  doc.setFillColor(74, 131, 192);
  doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
  doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, 'F');

  yPosition += 4;

  doc.text(title, xPosition, yPosition, null, null, 'center');

  yPosition += 4;

  // Reset text color to black
  doc.setTextColor(0);
  for (const key in damagePhotos) {
    consoleMsg('key', key);
    if (damagePhotos.hasOwnProperty(key)) {
      const { photo, ratio } = damagePhotos[key];
      const width = 180 * scaleFactor;
      const height = width * ratio; // Calculate height based on aspect ratio
      if (yPosition + height > pageHeight - 2 * margin) {
        doc.addPage();
        yPosition = margin;
      }
      doc.addImage(photo, 'PNG', margin, yPosition, width, height);
      consoleMsg('Adding damage photo at yPosition', yPosition);
      yPosition += height + sectionBreak;
      consoleMsg('Damage photo height = ', height + ' Page height = ', pageHeight);
      consoleMsg('New yPosition after adding damage photo: ', yPosition);
    }
  }

  if (yPosition + 75 + margin > pageHeight) {
    doc.addPage();
    consoleMsg('Adding new page for signature image');
    yPosition = margin;
  }

  title = 'Glovebox Check';
  //title = "x".repeat(90);
  xPosition = pageWidth / 2;
  doc.setFontSize(8);
  doc.setFillColor(74, 131, 192);
  doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
  doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, 'F');

  yPosition += 4;

  doc.text(title, xPosition, yPosition, null, null, 'center');

  yPosition += 4;

  // Reset text color to black
  doc.setTextColor(0);

  currentPage = 'page3';
  addDataTable(doc, page3, margin, yPosition, currentPage);

  finalY = doc.lastAutoTable.finalY;
  tableHeight = finalY - yPosition;

  // Update yPosition for the next section
  yPosition = finalY + sectionBreak;

  title = 'Interior Check';
  //title = "x".repeat(90);
  xPosition = pageWidth / 2;
  doc.setFontSize(8);
  doc.setFillColor(74, 131, 192);
  doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
  doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, 'F');

  yPosition += 4;

  doc.text(title, xPosition, yPosition, null, null, 'center');

  yPosition += 4;

  // Reset text color to black
  doc.setTextColor(0);

  currentPage = 'page4';
  addDataTable(doc, page4, margin, yPosition, currentPage);

  finalY = doc.lastAutoTable.finalY;
  tableHeight = finalY - yPosition;

  // Update yPosition for the next section
  yPosition = finalY + sectionBreak;

  title = 'Road Test';
  //title = "x".repeat(90);
  xPosition = pageWidth / 2;
  doc.setFontSize(8);
  doc.setFillColor(74, 131, 192);
  doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
  doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, 'F');

  yPosition += 4;

  doc.text(title, xPosition, yPosition, null, null, 'center');

  yPosition += 4;

  // Reset text color to black
  doc.setTextColor(0);

  currentPage = 'page5';
  addDataTable(doc, page5, margin, yPosition, currentPage);

  finalY = doc.lastAutoTable.finalY;
  tableHeight = finalY - yPosition;

  // Update yPosition for the next section
  yPosition = finalY + sectionBreak;

  title = 'Lights Check';
  //title = "x".repeat(90);
  xPosition = pageWidth / 2;
  doc.setFontSize(8);
  doc.setFillColor(74, 131, 192);
  doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
  doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, 'F');

  yPosition += 4;

  doc.text(title, xPosition, yPosition, null, null, 'center');

  yPosition += 4;

  // Reset text color to black
  doc.setTextColor(0);

  currentPage = 'page6';
  addDataTable(doc, page6, margin, yPosition, currentPage);

  finalY = doc.lastAutoTable.finalY;
  tableHeight = finalY - yPosition;

  // Update yPosition for the next section
  yPosition = finalY + sectionBreak;

  title = 'Fluid Inspection';
  //title = "x".repeat(90);
  xPosition = pageWidth / 2;
  doc.setFontSize(8);
  doc.setFillColor(74, 131, 192);
  doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
  doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, 'F');

  yPosition += 4;

  doc.text(title, xPosition, yPosition, null, null, 'center');

  yPosition += 4;

  // Reset text color to black
  doc.setTextColor(0);

  currentPage = 'page7';
  addDataTable(doc, page7, margin, yPosition, currentPage);

  finalY = doc.lastAutoTable.finalY;
  tableHeight = finalY - yPosition;

  // Update yPosition for the next section
  yPosition = finalY + sectionBreak;

  title = 'Tire Inspection';
  //title = "x".repeat(90);
  xPosition = pageWidth / 2;
  doc.setFontSize(8);
  doc.setFillColor(74, 131, 192);
  doc.setTextColor(255); // IMPORTANT: Make sure to reset text color to black after setting to white here
  doc.rect(0 + margin, yPosition, pageWidth - margin * 2, 8, 'F');

  yPosition += 4;

  doc.text(title, xPosition, yPosition, null, null, 'center');

  yPosition += 4;

  // Reset text color to black
  doc.setTextColor(0);

  currentPage = 'page8';
  addDataTable(doc, page8, margin, yPosition, currentPage);

  finalY = doc.lastAutoTable.finalY;
  tableHeight = finalY - yPosition;

  // Update yPosition for the next section
  yPosition = finalY + sectionBreak;

  // Add problems

  /*
	doc.setFontSize(12);
	title = "Problems";
	xPosition = pageWidth / 2;

	if (yPosition + margin > pageHeight) {
		doc.addPage();
		consoleMsg("Adding new page for legend image");
		yPosition = margin;
	}
	doc.text(title, xPosition, yPosition, null, null, "center");

	// Recalculate offset and add to current yPosition
	yOffset = calculateOffset(doc, lineHeight);
	yPosition += yOffset;

	let textLines = doc
		.setFont("Helvetica", "normal") //
		.setFontSize(fontSize)
		.setCharSpace(charSpacing)
		.splitTextToSize(myParagraph, maxLineWidth);

	const ptsPerMm = 0.352778;
	const oneLineHeight = fontSize * lineHeight * ptsPerMm;
	const textBlockHeight = textLines.length * oneLineHeight;
	consoleMsg("textLinesLenth", textLines.length);
	consoleMsg("textBlockHeight", textBlockHeight);

	
	yPosition += 2 * oneLineHeight;

	doc.text(textLines, margin, yPosition);

	yPosition += textBlockHeight;*/

  // Add digital signature

  if (yPosition + margin > pageHeight) {
    doc.addPage();
    consoleMsg('Adding new page for legend image');
    yPosition = margin;
  }
  doc.text('Digital Signature:', margin, yPosition);
  consoleMsg('Signature image position: ', yPosition);
  // Recalculate offset and add to current yPosition
  yOffset = calculateOffset(doc, lineHeight);
  yPosition += yOffset;
  if (signatureImage) {
    doc.addImage(signatureImage, 'PNG', 14, yPosition, 100, 30);
    yPosition += 40;
  }

  // Add footer to each page
  addFooter(doc, margin, pageWidth, pageHeight);

  // Save the PDF
  doc.save('inspection_summary.pdf');
  await store.set('inspection-summary', doc.output('datauristring'));

  // Convert the PDF to a Blob
  const pdfBlob = doc.output('blob');

  // Convert the Blob to Base64
  const reader = new FileReader();
  reader.readAsDataURL(pdfBlob);
  reader.onloadend = async function () {
    const base64data = reader.result.split(',')[1];

    // Save the Base64 encoded PDF to the file system
    const fileName = 'inspection_summary.pdf';

    await Filesystem.writeFile({
      path: fileName,
      data: base64data,
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    });

    // Open the saved PDF
    //await openPDF(fileName);
  };
}

// Function to retrieve form data from Ionic storage
async function getPages(...pageKeys) {
  try {
    // Use Promise.all to retrieve all stored objects concurrently
    const pages = await Promise.all(pageKeys.map((key) => store.get(key)));
    return pages;
  } catch (error) {
    console.error('Error retrieving pages from storage: ', error);
    return [];
  }
}

function addDetailsTable(doc, data, margin, yPosition) {
  const body = [];
  const dataKeys = Object.keys(data);
  const dataValues = Object.values(data);
  // Define the headers and data
  const headers = [];
  const values = [];
  for (let i = 0; i < dataKeys.length; i++) {
    headers.push(dataKeys[i]);
    values.push(dataValues[i]);
  }
  consoleMsg('Data keys length: ', dataKeys.length);
  for (let i = 0; i < headers.length; i += 2) {
    body.push([dataKeys[i], dataValues[i], dataKeys[i + 1], dataValues[i + 1]]);
  }
  formatTable(doc, body, margin, yPosition);
}

function formatTable(doc, body, margin, yPosition) {
  doc.autoTable({
    body: body,
    theme: 'grid',
    startY: yPosition,
    tableWidth: 'auto',
    styles: { fontSize: 8, valign: 'middle', minCellWidth: 15 },
    margin: { left: margin, right: margin },
    didParseCell: function (data) {
      if (data.section === 'body') {
        // Check to see if the cell has a fillColor explicitly defined (e.g., textarea values)
        if (data.cell.raw && data.cell.raw.styles && data.cell.raw.styles.fillColor) {
          data.cell.styles.fillColor = data.cell.raw.styles.fillColor;
        } else if (data.column.index % 2 === 0) {
          data.cell.styles.fillColor = [240, 240, 240]; // Light gray for even columns
        } else {
          data.cell.styles.fillColor = [255, 255, 255]; // White for odd columns
        }
      }
    },
  });
}

function setPageTitle(page) {
  let string = '';
  switch (page) {
    case '2':
      string = 'Exterior Inspection';
      break;
    case '3':
      string = 'Glovebox Check';
      break;
    case '4':
      string = 'Interior Check';
      break;
    case '5':
      string = 'Road Test';
      break;
    case '6':
      string = 'Lights Check';
      break;
    case '7':
      string = 'Fluid Inspection';
      break;
    case '8':
      string = 'Tire Inspection';
      break;
  }
  return string;
}

function addDataTable(doc, data, margin, yPosition, currentPage) {
  const body = [];
  const headers = [];
  const values = [];
  consoleMsg('addDataTable called...');
  consoleMsg('Data = ', data);
  const keys = Object.keys(data[currentPage]);
  consoleMsg('keys = ', keys);
  for (let i = 0; i < keys.length; i++) {
    const row = data[currentPage][i + 1];
    const page = row.page.slice(-1);
    consoleMsg('Page = ', page);
    headers.push(row.string);
    values.push(row.value);
  }

  consoleMsg('Headers length =', headers.length);
  for (let i = 0; i < headers.length; i++) {
    if (headers[i] === '') {
      // Check for empty string value, which indicates an ion textarea problem description
      body.push([
        { content: 'Problem details: ' + values[i], colSpan: 2, styles: { fillColor: [255, 255, 255] } },
      ]);
      // Check for problems and style value cell accordingly
    } else if ((values[i] === 'no') | (values[i] === 'low')) {
      body.push([
        { content: [headers[i]], colSpan: 1, styles: { cellWidth: 120 } },
        {
          content: [values[i]],
          colSpan: 1,
          styles: { fillColor: [249, 229, 231], textColor: [164, 10, 30] },
        },
      ]);
    } else {
      body.push([
        { content: [headers[i]], colSpan: 1, styles: { cellWidth: 120 } },
        { content: [values[i]], colSpan: 1 },
      ]);
    }
  }
  formatTable(doc, body, margin, yPosition);
}

function addTable(doc, data, margin, yPosition, cols = 4) {
  const body = [];
  const dataKeys = Object.keys(data);
  const dataValues = Object.values(data);
  // Define the headers and data
  const headers = [];
  const values = [];
  for (let i = 0; i < dataKeys.length; i++) {
    headers.push(dataKeys[i]);
    values.push(dataValues[i]);
  }
  consoleMsg('Data keys length: ', dataKeys.length);
  if (cols === 4) {
    for (let i = 0; i < headers.length; i += 2) {
      body.push([dataKeys[i], dataValues[i], dataKeys[i + 1], dataValues[i + 1]]);
    }
  } else {
    for (let i = 0; i < headers.length; i++) {
      consoleMsg('Data keys: ', dataKeys[i]);
      /*
			if (dataKeys[i] === "null" || dataKeys[i] === "") {
				consoleMsg("Null key: ", dataValues[i]);
				body.push([{ content: "Problem details: " + dataValues[i], colSpan: 2, styles: { fillColor: [255, 255, 255] } }]);
			*/
      if (dataKeys[i].includes('text') || dataKeys[i] === '') {
      } else {
        body.push([dataKeys[i], dataValues[i]]);
      }
    }
  }

  doc.autoTable({
    body: body,
    theme: 'grid',
    startY: yPosition,
    tableWidth: 'auto',
    styles: { fontSize: 8, valign: 'middle', minCellWidth: 15 },
    margin: { left: margin, right: margin },
    didParseCell: function (data) {
      if (data.section === 'body') {
        // Check to see if the cell has a fillColor explicitly defined (e.g., textarea values)
        if (data.cell.raw && data.cell.raw.styles && data.cell.raw.styles.fillColor) {
          data.cell.styles.fillColor = data.cell.raw.styles.fillColor;
        } else if (data.column.index % 2 === 0) {
          data.cell.styles.fillColor = [240, 240, 240]; // Light gray for even columns
        } else {
          data.cell.styles.fillColor = [255, 255, 255]; // White for odd columns
        }
      }
    },
  });
}

// Define a function to calculate the Y offset
function calculateOffset(doc, lineHeight) {
  let offset = doc.getFontSize() * lineHeight * 0.352778; // 0.352778 is the conversion factor from mm to pixels;
  offset = parseFloat(offset.toFixed(2));
  //consoleMsg("yOffset = ", offset);
  return offset;
}

function addFooter(doc, margin, pageWidth, pageHeight) {
  const totalPages = doc.getNumberOfPages();
  const currentDate = new Date().toLocaleDateString();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    const footerTitle = 'Vehicle Inspection Summary';
    const footerPage = `Page ${i} of ${totalPages}`;
    const footerDate = `${currentDate}`;
    doc.text(footerTitle, margin, pageHeight - 10, null, null, 'left');
    doc.text(footerPage, pageWidth / 2, pageHeight - 10, null, null, 'center');
    doc.text(footerDate, pageWidth - margin, pageHeight - 10, null, null, 'right');
  }
}

async function openPDF(fileName) {
  // Get the file URI
  const fileUri = await Filesystem.getUri({
    path: fileName,
    directory: Directory.Documents,
  });

  // Open the PDF using the Browser API
  await Browser.open({ url: fileUri.uri });
}

async function setTitle(titleString) {
  const title = titleString;
  doc.title = title;
}
