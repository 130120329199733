
		import { updateImagePaths, imgMapping } from "./js/images.js";
		app.loginContent = updateImagePaths(app.loginContent, imgMapping);
		app.pageOneContent = updateImagePaths(app.pageOneContent, imgMapping);
		app.pagePhotosContent = updateImagePaths(app.pagePhotosContent, imgMapping);
		app.pageTwoContent = updateImagePaths(app.pageTwoContent, imgMapping);
		app.pageThreeContent = updateImagePaths(app.pageThreeContent, imgMapping);
		app.pageFourContent = updateImagePaths(app.pageFourContent, imgMapping);
		app.pageFiveContent = updateImagePaths(app.pageFiveContent, imgMapping);
		app.pageSixContent = updateImagePaths(app.pageSixContent, imgMapping);
		app.pageSevenContent = updateImagePaths(app.pageSevenContent, imgMapping);
		app.pageEightContent = updateImagePaths(app.pageEightContent, imgMapping);
		app.signatureContent = updateImagePaths(app.signatureContent, imgMapping);
		app.appSettingsContent = updateImagePaths(app.appSettingsContent, imgMapping);
	