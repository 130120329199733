
		import { Login, PageOne, PagePhotos, PageTwo, PageThree, PageFour, PageFive, PageSix, PageSeven, PageEight, Signature, AppSettings } from "./js/classes.js";
		customElements.define("app-login", Login);
		customElements.define("inspection-details", PageOne);
		customElements.define("inspection-photos", PagePhotos);
		customElements.define("exterior-inspection", PageTwo);
		customElements.define("glovebox-check", PageThree);
		customElements.define("interior-check", PageFour);
		customElements.define("road-test", PageFive);
		customElements.define("lights-check", PageSix);
		customElements.define("fluid-inspection", PageSeven);
		customElements.define("tire-inspection", PageEight);
		customElements.define("app-signature", Signature);
		customElements.define("app-settings", AppSettings);
	