/*
********************************************************************************
File for importing images so that they can be optimized by Vite.  Used in 
damage-map.js and navigation.js.
********************************************************************************
*/
export { updateImagePaths, imgMapping, legendMapping, favIconLight16, favIconLight32, favIconDark16, favIconDark32 };

// Import all project images so image references in dynamic HTML can be updated with hashes
import legendImg from "../assets/img/legend.png";
import car1000 from "../assets/img/car-1000.png";
import car2000 from "../assets/img/car-2000.png";
//import logoImg186 from "../assets/img/logo-186.png";
import logoImg300 from "../assets/img/logo-300.png";
/*import logoImg372 from "../assets/img/logo-372.png";
import logoImg558 from "../assets/img/logo-558.png";
import logoImg600 from "../assets/img/logo-600.png";
import logoImg900 from "../assets/img/logo-900.png";
import logoDark186 from "../assets/img/logo-dark-186.png";
import logoDark300 from "../assets/img/logo-dark-300.png";
import logoDark372 from "../assets/img/logo-dark-372.png";
import logoDark558 from "../assets/img/logo-dark-558.png";
import logoDark600 from "../assets/img/logo-dark-600.png";
import logoDark900 from "../assets/img/logo-dark-900.png";*/
import crosshairs from "../assets/img/crosshairs-256.png";
import exclamationCircle from "../assets/img/exclamation-circle-256.png";
import exclamationTriangle from "../assets/img/exclamation-triangle-256.png";
import questionCircle from "../assets/img/question-circle-256.png";
import timesCircle from "../assets/img/times-circle-256.png";
import carFront from "../assets/img/car-front.png";
import carBack from "../assets/img/car-back.png";
import carDriverProfile from "../assets/img/car-driver-profile.png";
import carDriverSide from "../assets/img/car-driver-side.png";
import carPassengerProfile from "../assets/img/car-passenger-profile.png";
import carPassengerSide from "../assets/img/car-passenger-side.png";
import carTop from "../assets/img/car-top.png";
import carTrunk from "../assets/img/car-trunk.png";
import carInteriorFront from "../assets/img/car-interior-front.png";
import carInteriorRear from "../assets/img/car-interior-rear.png";
import carOdomometer from "../assets/img/car-odometer.png";
import carVin from "../assets/img/car-vin-label.png";
import favIconLight16 from "../assets/img/favicon-light-16.png";
import favIconLight32 from "../assets/img/favicon-light-32.png";
import favIconDark16 from "../assets/img/favicon-dark-16.png";
import favIconDark32 from "../assets/img/favicon-dark-32.png";

console.log("isDevelopment:", app.appOptions.isDevelopment);
// Variable to map hard-coded image references to hashed image paths
const imgMapping = {
	"legend.png": legendImg,
	"car-1000.png": car1000,
	"car-2000.png": car2000,
	//"logo-186.png": logoImg186,
	"logo-300.png": logoImg300,
	/*"logo-372.png": logoImg372,
	"logo-558.png": logoImg558,
	"logo-600.png": logoImg600,
	"logo-900.png": logoImg900,
	"logo-dark-186.png": logoDark186,
	"logo-dark-300.png": logoDark300,
	"logo-dark-372.png": logoDark372,
	"logo-dark-558.png": logoDark558,
	"logo-dark-600.png": logoDark600,
	"logo-dark-900.png": logoDark900,*/
	"crosshairs-256.png": crosshairs,
	"exclamation-circle-256.png": exclamationCircle,
	"exclamation-triangle-256.png": exclamationTriangle,
	"question-circle-256.png": questionCircle,
	"times-circle-256.png": timesCircle,
	"car-front.png": carFront,
	"car-back.png": carBack,
	"car-driver-profile.png": carDriverProfile,
	"car-driver-side.png": carDriverSide,
	"car-passenger-profile.png": carPassengerProfile,
	"car-passenger-side.png": carPassengerSide,
	"car-top.png": carTop,
	"car-trunk.png": carTrunk,
	"car-interior-front.png": carInteriorFront,
	"car-interior-rear.png": carInteriorRear,
	"car-odometer.png": carOdomometer,
	"car-vin-label.png": carVin,
};

const legendMapping = { "legend.png": legendImg, "car-1000.png": car1000, "logo-300.png": logoImg300 };

function updateImagePaths(content, images) {
	// Only update production image paths
	if (!app.appOptions.isDevelopment) {
		//console.log('Updating image paths');
		Object.keys(images).forEach((key) => {
			//console.log('Original content:', content);
			const regex = new RegExp(`assets/img/${key}`, "g");
			//console.log(`Replacing ${regex} with ${imgMapping[key]}`);
			content = content.replace(regex, imgMapping[key]);
		});
		//console.log('Updated content:', content);
	}
	return content;
}

// Make images available for import in other modules
export { legendImg, car1000, car2000, logoImg186, logoImg300, logoImg372, logoImg558, logoImg600, logoImg900, crosshairs, exclamationCircle, exclamationTriangle, questionCircle, timesCircle };
