
		if ("serviceWorker" in navigator) {
			// Check if the app is running on an iOS device
			const platform = Capacitor.getPlatform();
			const isIOS = platform === "ios";
			console.log("Platform:", platform);
			console.log("isIOS:", isIOS);

			console.log("Service worker supported on this device");
			navigator.serviceWorker.register("/service-worker.js").then(
				(registration) => {
					console.log("ServiceWorker registration successful with scope: ", registration.scope);
				},
				(err) => {
					console.log("ServiceWorker registration failed: ", err);
					alert("ServiceWorker registration failed. Please check your internet connection and try restarting the Inspection application.");
				}
			);

			navigator.serviceWorker.addEventListener("message", (event) => {
				if (event.data && event.data.type === "RELOAD_PAGE") {
					console.log("Reloading page...");
					window.location.reload();
				}

				if (event.data && event.data.type === "NETWORK_ERROR") {
					alert(
						"A resource required for this operation failed to load:" +
							event.data.url +
							". Please check your internet connection. To continue working \
              in offline mode, refresh this page (any unsaved data from current page will be lost)."
					);
					// Force a page refresh so app continues to work
					//window.location.reload();
				}
			});
		} else {
			console.log("Service worker not supported on this device");
		}
	