/* 
********************************************************
Damage Map (myMap) functions
********************************************************
*/

export {
  restoreDamageMarkers,
  mapClicked,
  resetDialogState,
  generateDamageImage,
  storeDamageMarkers,
  removeMarker,
};

import { consoleMsg } from './utility.js';
import { imgMapping } from './images.js';

// Define global variables for storing marker coordinates
let x, y, rect;

async function restoreDamageMarkers() {
  consoleMsg('Restoring damage markers...');
  consoleMsg('Current damageMarkers object: ', app.damageMarkers);
  const markers = await store.get('damage-markers');

  // Loop through each key in the global damageMarkers object
  for (let key in markers) {
    let keyArray = [];
    for (let key2 in markers[key]) {
      consoleMsg(key2 + ' ' + markers[key][key2]);
      keyArray.push(markers[key][key2]);
    }
    consoleMsg('Key array = ', keyArray);
    consoleMsg('Calling addMarker with parameters: ', keyArray);
    addMarker(...keyArray, 'myMap');
  }
}

function mapClicked(event) {
  //consoleMsg("Event = ", event);
  event.stopPropagation();
  consoleMsg('MAP CLICKED');
  // Get bounding rectangle dimensions of parent div
  rect = event.target.getBoundingClientRect();
  consoleMsg('R Width: ' + rect.width + ' R Height: ' + rect.height);
  // Calculate x and y coordinate values as offset from top / left of parent viewport
  x = event.clientX - rect.left;
  y = event.clientY - rect.top;
  if (!rect) {
    console.error('Rect is undefined in map click handler');
    return;
  }
  if (event.target.id === 'myImg') {
    showDamageDialog('alert1', x, y, rect);
    consoleMsg('myMap coordinates:');
    consoleMsg(x, y);
  } else {
    consoleMsg(event.target);
  }
  consoleMsg('myMap ' + event.hasListener);
}

function showDamageDialog(id, x, y, rect) {
  const dialog = document.getElementById(id);
  consoleMsg('showDamageDialog function: ' + x + ' ' + y);
  if (dialog) {
    dialog.present();
  }
  // Remove any existing event listener before adding a new one
  document.removeEventListener('click', okEventHandler);

  // Add the click event listener using document and event delegation
  document.addEventListener('click', okEventHandler);
}

// Define the event handler as a named function
const okEventHandler = function (event) {
  const target = event.target;

  // Ensure that the clicked button is the correct one using event.target
  if (target && target.id === 'damage-ok') {
    consoleMsg('Button clicked with target: ', target);
    okEventListener(x, y, rect);

    // Reset alert dialog state
    resetDialogState();
  }
};

// Function to reset the dialog state
function resetDialogState() {
  // Reset any variables holding coordinate data
  x = null;
  y = null;
  rect = null;

  // Ensure any event listeners related to the dialog are removed
  document.removeEventListener('click', okEventHandler);
  consoleMsg('Dialog state has been reset');
}

function updateMarkerPaths(name, map) {
  /* Only update marker paths in production mode: the Vite build process was causing the regex to
  fail in dev because it was adding an extra '/' slash at the start of the path name. */
  if (!app.appOptions.isDevelopment) {
    consoleMsg('Updating marker paths...');
    Object.keys(map).forEach((key) => {
      if (name.includes(`assets/img/${key}`)) {
        console.log('Original name:', name);
        const regex = new RegExp(`assets/img/${key}`, 'g');
        //console.log(`Replacing ${regex} with ${map[key]}`);
        name = name.replace(regex, map[key]);
        //console.log('Updated content:', name);
      }
    });
  }
  return name;
}

async function okEventListener(x, y, rect) {
  var selectVal = app.alert1Data;
  var iSrc, iAlt;
  consoleMsg('okEventListener triggered...');
  printCoordinates(x, y);
  consoleMsg(selectVal);
  if (!app.alert1Data) {
    consoleMsg('No damage selected.');
    return;
  }
  switch (selectVal) {
    case 'scrape':
      iSrc = 'assets/img/exclamation-triangle-256.png';
      iSrc = updateMarkerPaths(iSrc, imgMapping);
      iAlt = 'Scrape damage icon';
      break;
    case 'scratch':
      iSrc = 'assets/img/times-circle-256.png';
      iSrc = updateMarkerPaths(iSrc, imgMapping);
      iAlt = 'Scratch damage icon';
      break;
    case 'dent':
      iSrc = 'assets/img/crosshairs-256.png';
      iSrc = updateMarkerPaths(iSrc, imgMapping);
      iAlt = 'Dent damage icon';
      break;
    case 'broken':
      iSrc = 'assets/img/exclamation-circle-256.png';
      iSrc = updateMarkerPaths(iSrc, imgMapping);
      iAlt = 'Broken damage icon';
      break;
    case 'missing':
      iSrc = 'assets/img/question-circle-256.png';
      iSrc = updateMarkerPaths(iSrc, imgMapping);
      iAlt = 'Missing damage icon';
      break;
  }
  if (!rect) {
    console.error('rect is undefined in myOk event listener');
  }

  // Capture each marker in a 'damageMarkers' object
  let markerIndex = 'marker';
  markerIndex += Object.keys(app.damageMarkers).length;
  consoleMsg('Next marker name = ', markerIndex);
  addMarker(x, y, rect.width, rect.height, iSrc, iAlt);
  app.damageMarkers[markerIndex] = {};
  app.damageMarkers[markerIndex].x = x;
  app.damageMarkers[markerIndex].y = y;
  app.damageMarkers[markerIndex].width = rect.width;
  app.damageMarkers[markerIndex].height = rect.height;
  app.damageMarkers[markerIndex].src = iSrc;
  app.damageMarkers[markerIndex].alt = iAlt;
}

async function printCoordinates(x, y) {
  consoleMsg('okEventCoordinates: ' + x, y);
}

async function addMarker(x, y, w, h, iSrc, iAlt, markerElement = 'myMap') {
  consoleMsg('addMarker function called...');
  consoleMsg('Parameters at start: ', { x, y, w, h, iSrc, iAlt, markerElement });

  var i = new Image();
  var btn = document.getElementById('removeMarker');
  const markerContainer = document.getElementById(markerElement);

  if (!markerContainer) {
    console.error('Marker container not found: ', markerElement);
    return;
  }

  // Round down width and height values to two decimal places
  w = parseFloat(w).toFixed(2);
  h = parseFloat(h).toFixed(2);
  i.src = iSrc;
  i.alt = iAlt;
  i.style.position = 'absolute';

  // Await the image loading
  await new Promise((resolve) => {
    i.onload = function () {
      // Use the dimensions of the marker container for the calculations
      const containerHeight = markerContainer.offsetHeight;
      const containerWidth = markerContainer.offsetWidth;

      // Calculate icon offset as percentage of viewport width
      let offset = (containerWidth * 0.032) / 2;

      // Set image insertion coordinates, including offset for calculated image size
      const top = ((y - offset) / containerHeight) * 100;
      const left = ((x - offset) / containerWidth) * 100;
      consoleMsg('Container height = ' + containerHeight + ' Container width = ' + containerWidth);
      i.style.top = top + '%';
      i.style.left = left + '%';

      // Add class for specifying image width as a percentage of parent container
      i.classList.add('map-icon');

      // Resolve the promise once the image is loaded
      resolve();
    };
  });

  // Append the image to the DOM
  markerContainer.appendChild(i);
  consoleMsg('Marker added to DOM: ', i, ' Marker element: ', markerElement);

  // If function target is 'myMap', enable 'Remove Marker' button
  if (markerElement === 'myMap') {
    consoleMsg('Marker element is myMap...');
    btn.removeAttribute('disabled');
  }
}

async function generateDamageImage(markers, canvasId = 'damageCanvas', src = imgMapping['car-1000.png']) {
  consoleMsg('Generating damage image...', imgMapping);
  const canvas = document.getElementById(canvasId);
  //const imgSrc = updateMarkerPaths(src, legendMapping);
  const imgSrc = updateMarkerPaths(src, imgMapping);
  const baseImageSrc = imgSrc;
  let newImage = 'legend-image';
  const ctx = canvas.getContext('2d');

  // Save damage markers to Ionic storage
  await store.set('damage-markers', markers);

  // Create a new image element for the base damage image
  const baseImage = new Image();
  baseImage.src = baseImageSrc;
  consoleMsg('Base image source: ', baseImageSrc);

  baseImage.onload = async function () {
    // Set canvas size to match the base image
    canvas.width = baseImage.width;
    canvas.height = baseImage.height;

    // Draw the base image onto the canvas
    ctx.drawImage(baseImage, 0, 0);

    // Iterate over each marker and draw them on the canvas
    if (canvasId === 'damageCanvas') {
      newImage = 'damage-image';
      Object.keys(markers).forEach(function (markerKey) {
        const marker = markers[markerKey];
        drawMarkerOnCanvas(ctx, canvas, marker);
      });
    } else if (canvasId === 'logoCanvas') {
      newImage = 'logo-image';
    }

    // Wait for a short period to ensure all images are drawn
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Generate PNG data URL
    const dataURL = canvas.toDataURL('image/png');

    // Save the generated PNG to Ionic storage
    await store.set(newImage, dataURL);

    // Save damage markers to Ionic storage
    //await store.set("damage-markers", markers);

    // Optionally, download the image
    //downloadImage(dataURL, "damage_image.png");
    consoleMsg(newImage + ' image generated and saved to storage');
  };
}

async function storeDamageMarkers(markers) {
  const keys = Object.keys(markers);
  consoleMsg(keys);
  if (keys.length > 0) {
    consoleMsg('STORING DAMAGE MARKERS...');
    await store.set('damage-markers', markers);
  }
}

// Function to draw a marker directly on the canvas
function drawMarkerOnCanvas(ctx, canvas, marker) {
  consoleMsg('Drawing marker on canvas: ', marker);
  const img = new Image();
  img.src = marker.src;
  img.alt = marker.alt;

  img.onload = function () {
    marker.width = 32;
    marker.height = 32;

    const imgClass = document.createElement('div');
    imgClass.appendChild(img);

    // Draw the marker onto the canvas with offset
    //ctx.drawImage(img, markerX - offsetX, markerY - offsetY, marker.width, marker.height);
    ctx.drawImage(img, marker.x - 16, marker.y - 16, marker.width, marker.height);
  };
}

function downloadImage(dataURL, filename) {
  const link = document.createElement('a');
  link.href = dataURL;
  link.download = filename;
  link.click();
}

function removeMarker() {
  var mapDiv = document.getElementById('myMap');
  var btn = document.getElementById('removeMarker');
  mapDiv.removeChild(mapDiv.lastChild);
  consoleMsg(mapDiv.children + ' Count: ' + mapDiv.children.length);
  // Disable 'Remove Marker' button when the car map image is the last child to prevent its deletion
  if (mapDiv.children.length === 1) {
    btn.setAttribute('disabled', '');
  }
  // Begin adding logic for persisting markers in 'damageMarkers' object
  let markerIndex = 'marker';
  markerIndex += Object.keys(app.damageMarkers).length - 1;
  consoleMsg(markerIndex);
  delete app.damageMarkers[markerIndex];
}
