/*
********************************************************************************
Capacitor-related functions
********************************************************************************
*/

import { Storage } from "@ionic/storage";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";

import { savePhoto, saveCarPhoto } from "./storage.js";
import { consoleMsg } from "./utility.js";

export { takePhoto, viewPhoto };

//import { SplashScreen } from "@capacitor/splash-screen";

// Hide the splash (you should do this on app launch)
//await SplashScreen.hide();

// Show the splash for two seconds and then automatically hide it:
/*await SplashScreen.show({
	showDuration: 2000,
	autoHide: true,
});*/

let store;

async function initStorage() {
	store = new Storage();
	await store.create();
	consoleMsg("Ionic Storage is ready");

	// Assign store to the global window object for access in the console
	window.store = store;
}

initStorage();

const keys = await store.keys();

if (keys.length > 0 && keys.includes("damage-markers")) {
	consoleMsg("Damage markers in Ionic storage...");
	app.damageMarkers = await store.get("damage-markers");
} else {
	consoleMsg("DM NOT STORED...");
}

async function takePhoto(element) {
	consoleMsg("Camera object = ", Camera);
	consoleMsg("Invoking element: ", element);
	try {
		const image = await Camera.getPhoto({
			//quality: 90,
			quality: 100,
			allowEditing: false,
			resultType: CameraResultType.Uri,
			source: CameraSource.Camera,
		});
		if (app.currentPage === "page2") {
			savePhoto(element, image.webPath);
		} else {
			saveCarPhoto(element, image.webPath);
		}
	} catch (error) {
		consoleMsg("Error taking photo: ", error);
	}
}

function viewPhoto(photoUrl) {
	// Create the modal container
	const modal = document.createElement("div");
	modal.classList.add("photo-modal"); // Use CSS class for styling

	// Create the image element
	const image = document.createElement("img");
	image.src = photoUrl;
	image.alt = "Full-size photo";
	image.classList.add("photo-modal-image"); // Use CSS class for styling

	// Add the image to the modal
	modal.appendChild(image);

	// Add a click event to close the modal
	modal.addEventListener("click", () => {
		modal.remove();
	});

	// Append the modal to the body
	document.body.appendChild(modal);
}

// Check if the Capacitor object is available
if (Capacitor) {
	console.log("Capacitor object is available.");

	// Check if the app is running on an iOS device
	const platform = Capacitor.getPlatform();
	console.log("Capacitor.getPlatform() returned:", platform);
	const isIOS = platform === "ios";
	console.log("Platform:", platform);
	console.log("isIOS:", isIOS);
} else {
	console.error("Capacitor object is not available.");
}

if (Capacitor.isPluginAvailable("StatusBar")) {
	window.addEventListener("statusTap", function () {
		consoleMsg("statusbar tapped");
	});
}

// Function to wait for the content to be fully loaded
function waitForContent(selector) {
	return new Promise((resolve) => {
		const checkContent = () => {
			const element = document.querySelector(selector);
			if (element) {
				resolve(element);
			} else {
				requestAnimationFrame(checkContent);
			}
		};
		checkContent();
	});
}
