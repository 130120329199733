/*
********************************************************************************
ENTRY POINT JS FILE: Class definitions for page components.  NOTE: service 
worker registration moved to a module script block in index.html.
********************************************************************************
*/

//@ts-nocheck
// Toggle preceding from @ts-nocheck to @ts-check to enable TypeScript checking

/*; // Import Ionic CSS
import '@ionic/pwa-elements';
import '@ionic/core/loader';
import '@ionic/core/components';*/

import { consoleMsg } from "./utility.js";
import { takePhoto, viewPhoto } from "./index.js";
import { generateInspectionPDF } from "./pdf.js";
import { isLocalStorageAvailable, deletePhoto, storeTableData } from "./storage.js";
import { setMenu, setHeader, setFooter, updateOfflineStatus, selectRadios, populateTable, addTableSkeletons } from "./utility.js";
import { imgMapping, legendMapping, favIconLight16, favIconLight32, favIconDark16, favIconDark32 } from "./images.js";
import { next, previous, validatePage, checkPhotos, restoreFromLocalStorage, showDiv, hideDiv, showTextArea } from "./navigation.js";
import { restoreDamageMarkers, mapClicked, resetDialogState, storeDamageMarkers, removeMarker } from "./damage-map.js";

/* Importing SignaturePad from node_modules, and required functions from signature-functions.js
(previously app.js)
*/
import SignaturePad from "signature_pad";
import { resizeCanvas, download, dataURLToBlob } from "./signature-functions.js";
import "./ionic-imports.js";

import { defineCustomElements } from "@ionic/pwa-elements/loader";
defineCustomElements(window, {
	resourcesUrl: "/node/", // Adjust the base path to your local files
});

// Temporary data to populate page1 inspection details tables
// prettier-ignore
const dataTables = {
  'vehicle-info-table': {
    'Fleet Number': 'F022-9531',
    'Brand': 'Ford',
    'Model': 'Mustang Mach-E',
    'VIN': '9531'
  },
  'loan-info-table': {
    'Delivery Date': '01/03/20225',
    'Return Date': '01/10/2025',
    'Loan Type': 'Media Review',
    'Job Number': 'FO1498F - MCS LA Fleet',
    'Activity Number': '555849',
    'DocuSign Status': 'N/A'
  },
  'contact-info-table': {
    'Name': 'Jeff Glucker',
    'Affiliation': 'Hooniverse',
    'Cell Phone': '(617) 869-8477',
    'Primary Phone': '(617) 869-8477',
    'Delivery Address': '24658 Via Raza, Lake Forest, CA 92630',
  }
};

// Set inspector value: need API from Trevor to retrieve
const inspector = "Connor Castro";

setInterval(updateOfflineStatus, 30000);
//setInterval(updateOfflineStatus, 5000);

// Call updateOfflineStatus immediately on page load
updateOfflineStatus();

// Populate photosData object with image mappings from hashed manifest file
/*app.photoData = {
	"car-front": { src: imgMapping["car-front.png"], updated: false },
	"car-driver-side": { src: imgMapping["car-driver-side.png"], updated: false },
	"car-passenger-side": { src: imgMapping["car-passenger-side.png"], updated: false },
	"car-back": { src: imgMapping["car-back.png"], updated: false },
	"car-interior-front": { src: imgMapping["car-interior-front.png"], updated: false },
	"car-interior-rear": { src: imgMapping["car-interior-rear.png"], updated: false },
	"car-vin": { src: imgMapping["car-vin-label.png"], updated: false },
	"car-top": { src: imgMapping["car-top.png"], updated: false },
	"car-driver-profile": { src: imgMapping["car-driver-profile.png"], updated: false },
	"car-passenger-profile": { src: imgMapping["car-passenger-profile.png"], updated: false },
	"car-trunk": { src: imgMapping["car-trunk.png"], updated: false },
	"car-odometer": { src: imgMapping["car-odometer.png"], updated: false },
};*/

// Temporarilly setting updated flag to true to avoid validation
app.photoData = {
	"car-front": { src: imgMapping["car-front.png"], updated: true },
	"car-driver-side": { src: imgMapping["car-driver-side.png"], updated: true },
	"car-passenger-side": { src: imgMapping["car-passenger-side.png"], updated: true },
	"car-back": { src: imgMapping["car-back.png"], updated: true },
	"car-interior-front": { src: imgMapping["car-interior-front.png"], updated: true },
	"car-interior-rear": { src: imgMapping["car-interior-rear.png"], updated: true },
	"car-vin": { src: imgMapping["car-vin-label.png"], updated: true },
	"car-top": { src: imgMapping["car-top.png"], updated: true },
	"car-driver-profile": { src: imgMapping["car-driver-profile.png"], updated: true },
	"car-passenger-profile": { src: imgMapping["car-passenger-profile.png"], updated: true },
	"car-trunk": { src: imgMapping["car-trunk.png"], updated: true },
	"car-odometer": { src: imgMapping["car-odometer.png"], updated: true },
};

// Centralized Mutation Observer
const observer = new MutationObserver((mutations) => {
	mutations.forEach((mutation) => {
		const modal = document.querySelector(".pwa-canera");
		if (modal) {
			consoleMsg("PWA CAMERA OBJECT INVOKED...");
			const shutterButton = modal.querySelector(".shutter-button");
			if (shutterButton) {
				shutterButton.style.backgroundColor = "red"; // Example style
				shutterButton.style.borderRadius = "50%"; // Example style
			}
		}
	});
});

// Observe the DOM for child changes globally
observer.observe(document.body, { childList: true, subtree: true });

// Define the common function for initializing page classes (starting from page2)
async function initializePage(pageNo) {
	const pageId = "page" + pageNo;
	const formId = "form" + pageNo;
	const alertId = "alert" + pageNo;
	const pageRadioGroups = document.querySelectorAll("ion-radio-group");
	app.backButton = document.getElementById("app.backButton" + pageNo);
	app.nextButton = document.getElementById("pushButton" + pageNo);
	consoleMsg(`${pageId} entered`);
	app.form = document.getElementById(formId);
	app.currentPage = pageId;
	restoreFromLocalStorage();

	// Set common page elements and check offline status
	await setMenu("menu-" + pageNo);
	await setHeader("header-" + pageNo, inspector);
	await setFooter("footer-" + pageNo);
	await updateOfflineStatus("offline-" + pageNo);

	// Attach refresher event listener
	const refresher = document.querySelector(`#${pageId} ion-refresher`);
	if (refresher) {
		refresher.addEventListener("ionRefresh", handleRefresh);
	}

	if (app.appOptions.checkRadios) {
		selectRadios(pageRadioGroups);
	}

	// Definition of text / photo reset alert
	const photoAlert = document.getElementById(alertId);
	if (photoAlert) {
		photoAlert.buttons = [
			{
				text: "Cancel",
				role: "cancel",
				handler: () => {
					// Define varible for passed alert data
					const alertData = alert.data.textId;
					consoleMsg("Alert data = ", alertData);
					// Derive other constants via string manipulation of passed data
					const radioGroupId = alertData.slice(0, -5) + "-group";
					const radioGroup = document.getElementById(radioGroupId);
					if (radioGroup) {
						// Reset radio group value to 'No' and dispatch ionChange event to update UI
						radioGroup.value = "no";
						radioGroup.dispatchEvent(
							new CustomEvent("ionChange", {
								bubbles: true,
							})
						);
					}
				},
			},
			{
				text: "OK",
				role: "confirm",
				handler: () => {
					// Define constants for passed alert data
					const textId = photoAlert.data.textId;
					const textArea = photoAlert.data.textArea;
					const divId = photoAlert.data.divId;
					const photos = document.querySelectorAll("#" + divId + " img");
					const photoRow = document.querySelector("#" + divId + " ion-row");
					if (photoRow) {
						const photoRowId = photoRow.id;
					}
					consoleMsg("Alert confirmed");
					// Reset text area value and hide parent div
					textArea.value = "";
					// If there are any photos in the current row, remove from DOM and storage
					if (photos.length > 0) {
						for (let i = 0; i < photos.length; i++) {
							photos[i].remove();
						}
						store.forEach((value, key) => {
							if (key.includes(photoRowId)) {
								store.remove(key);
							}
						});
					}
					hideDiv(divId);
				},
			},
		];
	}

	// Add event listeners for each page radio group
	pageRadioGroups.forEach((radioGroup) => {
		radioGroup.addEventListener("ionChange", showTextArea);
	});

	// Remove existing event listeners for back and next buttons
	app.backButton.removeEventListener("click", previous);
	app.nextButton.removeEventListener("click", validatePage);
}

function handleRefresh(event) {
	console.log("Pull-to-refresh triggered");

	// Simulate refresh logic (e.g., update the offline status or reload data)
	setTimeout(() => {
		console.log("Refresh complete");
		event.target.complete(); // Stop the refresher spinner
		window.location.reload();
	}, 1000);
}

function setupGridPhotoListeners() {
	const photoIcons = document.querySelectorAll('.photo-title ion-icon[name="camera"]');
	const photos = {}; // To track photos for each column by index
	const keys = Object.keys(app.photoData);

	photoIcons.forEach((icon, index) => {
		icon.addEventListener("click", async () => {
			const actionSheet = document.createElement("ion-action-sheet");
			const key = keys[index];
			actionSheet.header = `Actions for ${icon.closest(".photo-title").textContent.trim()}`;
			actionSheet.buttons = [
				{
					text: "Take Photo",
					icon: "camera",
					handler: async () => {
						consoleMsg(`Taking photo for column ${index}`);
						const imageUrl = await takePhoto(key);
						setTimeout(() => {
							//replacePhoto(key, imageUrl);
						}, 200);

						/*photos[index] = imageUrl; // Store the captured photo
            consoleMsg('Current key: ', key);
            app.photoData[key].src = imageUrl;*/
					},
				},
				{
					text: "View Full-size Photo",
					icon: "image",
					handler: async () => {
						consoleMsg(`Viewing photo for column ${index}`);
						await viewPhoto(app.photoData[key].src);
					},
					disabled: !app.photoData[key].updated,
					//disabled: !photos[index],
				},
				{
					text: "Cancel",
					role: "cancel",
					icon: "close",
				},
			];

			document.body.appendChild(actionSheet);
			await actionSheet.present();
		});
	});
}

function updateCarPhotos() {
	const keys = Object.keys(app.photoData);
	consoleMsg("updateCarPhotos function called...");
	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		if (app.photoData[key].updated === true) {
			const element = document.getElementById(key);
			const image = element.firstElementChild;
			image.src = "";
			setTimeout(() => {
				image.src = app.photoData[key].src;
			}, 50);
		}
	}
}

async function replacePhoto(key, url) {
	consoleMsg("replacePhoto function called...");
	consoleMsg("Replacing photo...");
	consoleMsg("URL = ", url);
	app.photoData[key].src = url;
	consoleMsg("New value = ", app.photoData[key].src);
	const element = document.getElementById(key);
	const image = element.firstElementChild;
	image.src = url;
}

console.log("Favicon = ", favIconLight16);

// Function to update the favicon
function updateFavicon() {
	const fav16 = document.getElementById("fav-16");
	const fav32 = document.getElementById("fav-32");
	const logo = document.getElementBy;
	const darkModeMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

	if (darkModeMediaQuery.matches) {
		// User prefers dark mode
		fav16.href = favIconDark16;
		fav32.href = favIconDark32;
	} else {
		// User prefers light mode
		fav16.href = favIconLight16;
		fav32.href = favIconDark32;
	}
}

updateFavicon();

// Listen for changes in the color scheme preference
window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", updateFavicon);

export class Login extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.loginContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await setMenu("menu-login");
			await setHeader("header=login");
			await setFooter("footer-login");
			await updateOfflineStatus("offline-login");
		});
		this.addEventListener("ionViewDidEnter", async () => {
			consoleMsg("Login page entered");

			const loginButton = document.getElementById("login");
			const user = document.getElementById("username");
			const password = document.getElementById("password");

			app.currentPage = "login";
			consoleMsg("Current page = " + app.currentPage);

			loginButton.addEventListener("click", () => next("/inspection-details"));
		});
		const refresher = this.querySelector("ion-refresher");
		consoleMsg("This:", this);
		if (refresher) {
			refresher.addEventListener("ionRefresh", handleRefresh);
		}
	}
}

export class PageOne extends HTMLElement {
	connectedCallback() {
		consoleMsg("Custom element pageOne connected");
		//consoleMsg("legendMapping:", legendMapping);
		//consoleMsg("imgMapping:", imgMapping);
		this.innerHTML = app.pageOneContent;
		this.addEventListener("ionViewWillEnter", async () => {
			// Add placeholders (ion-skeleton) for all tables
			for (const [tableId, data] of Object.entries(dataTables)) {
				const rowCount = Object.keys(data).length; // Determine the number of rows needed
				addTableSkeletons(tableId, rowCount); // Add placeholders for each table
			}
			await setMenu("menu-1");
			await setHeader("header-1", inspector);
			await setFooter("footer-1");
			await updateOfflineStatus("offline-1");
		});

		this.addEventListener("ionViewDidEnter", async () => {
			// Simulate data fetch delay for all tables at once
			await new Promise((resolve) => setTimeout(resolve, 200));

			// Populate all tables with actual data
			for (const [tableId, data] of Object.entries(dataTables)) {
				populateTable(tableId, data); // Populate each table
			}

			consoleMsg("Page 1 entered");
			app.currentPage = "page1";
			await storeTableData(dataTables);
			document.addEventListener("click", ({ target }) => {
				if (target.matches("#pushButton")) {
					consoleMsg("#pushButton event listener added...");
					next("/inspection-photos");
				}
			});
			const refresher = this.querySelector("ion-refresher");
			if (refresher) {
				refresher.addEventListener("ionRefresh", handleRefresh);
			}
		});
	}
	disconnectedCallback() {
		consoleMsg("Custom element pageOne disconnected");
	}
}

export class PagePhotos extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.pagePhotosContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await initializePage(9);
		});

		this.addEventListener("ionViewDidEnter", async () => {
			consoleMsg("Photos page entered");
			setupGridPhotoListeners();
			updateCarPhotos();

			//Add event listeners to photo cells
			document.querySelectorAll(".landscape-img").forEach((cell) => {
				cell.addEventListener("click", (event) => {
					//event.stopPropagation;
					consoleMsg("CELL CLICKED...");
					const photoName = cell.getAttribute("name");
					const photoUrl = app.photoData[photoName].src;
					consoleMsg("Name: ", photoUrl);
					if (photoUrl) {
						viewPhoto(photoUrl);
					}
				});
			});

			app.backButton.addEventListener("click", () => previous("/inspection-details"));
			app.nextButton.addEventListener("click", () => validatePage("/exterior-inspection"));
		});
		this.addEventListener("ionViewWillLeave", () => {
			consoleMsg("Leaving photos page...");
		});
	}
}

export class PageTwo extends HTMLElement {
	connectedCallback() {
		consoleMsg("Custom element pageTwo connected");
		this.innerHTML = app.pageTwoContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await initializePage(2);
		});
		this.addEventListener("ionViewDidEnter", async () => {
			const alert1 = document.getElementById("alert1");
			const storage = isLocalStorageAvailable();

			app.nextButton.addEventListener("click", () => validatePage("/glovebox-check"));
			app.backButton.addEventListener("click", () => storeDamageMarkers(app.damageMarkers));
			app.backButton.addEventListener("click", () => previous("/inspection-photos"));

			restoreDamageMarkers();

			// Map functionality wasn't working when navigating back to page, so moving event listener bindings to here

			// Custom alert settings
			alert1.inputs.id = "select-damage-type";
			alert1.inputs = [
				{
					label: "Scrape",
					type: "radio",
					value: "scrape",
					checked: true,
				},
				{
					label: "Scratch",
					type: "radio",
					value: "scratch",
				},
				{
					label: "Dent",
					type: "radio",
					value: "dent",
				},
				{
					label: "Broken",
					type: "radio",
					value: "broken",
				},
				{
					label: "Missing",
					type: "radio",
					value: "missing",
				},
			];
			alert1.inputs.value = "scrape";

			alert1.buttons = [
				{
					text: "Cancel",
					role: "cancel",
					id: "damage-cancel",
					handler: () => {
						consoleMsg("Alert canceled");
						alert1.dismiss();
						resetDialogState();
					},
				},
				{
					text: "OK",
					role: "confirm",
					id: "damage-ok",
					// Pass the undocumented alertData parameter to the handler function
					handler: (alertData) => {
						app.alert1Data = alertData;
						consoleMsg("Selected value: " + alertData);
						// ionAlertDidDismiss event listener wasn't working without adding the below timeout
						setTimeout(() => {
							alert1.dismiss();
						}, 100);
						return { confirm: true };
					},
				},
			];

			alert1.addEventListener("ionAlertDidDismiss", (event) => {
				consoleMsg(`Dismissed with role: ${event.detail.role}`);
			});

			const myMap = document.getElementById("myMap");
			const removeMarkerButton = document.getElementById("removeMarker");

			myMap.addEventListener("click", (event) => {
				mapClicked(event);
			});

			removeMarkerButton.addEventListener("click", (event) => {
				removeMarker();
			});

			/* Photo handling functions */

			// Function to handle the click event
			async function handlePhotoClick(event) {
				const buttonId = event.target.id;
				const rowId = buttonId.replace("takePhotoButton", "photoRow");
				const rowElement = document.getElementById(rowId);
				const deleteButton = "deletePhotoButton" + this.id.substr(-1);

				if (rowElement) {
					consoleMsg("Button clicked...");
					const imageUrl = await takePhoto(rowElement);
				} else {
					console.error(`Row element not found for button ID: ${buttonId}`);
				}
			}

			// Function to attach event listeners to all buttons with IDs starting with 'takePhotoButton'
			function attachPhotoEventListeners() {
				const takeButtons = document.querySelectorAll('ion-button[id^="takePhotoButton"]');
				const deleteButtons = document.querySelectorAll('ion-button[id^="deletePhotoButton"]');
				takeButtons.forEach((button) => {
					//consoleMsg("Attaching event listener to button with ID:", button.id);
					button.addEventListener("click", handlePhotoClick);
				});
				deleteButtons.forEach((button) => {
					//consoleMsg("Attaching event listener to button with ID:", button.id);
					button.addEventListener("click", deletePhoto);
				});
			}

			/* End photo handling functions */

			attachPhotoEventListeners();
		});

		this.addEventListener("ionViewWillLeave", () => {
			// Needed to add this to quit image loading from firing when leaving page: ask Beaie why
			//app.currentPage = undefined;
			consoleMsg("Page2 view left");
		});
	}
	disconnectedCallback() {
		consoleMsg("Custom element pageTwo disconnected");
	}
}

export class PageThree extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.pageThreeContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await initializePage(3);
		});
		this.addEventListener("ionViewDidEnter", async () => {
			const insuranceExpAlert = document.getElementById("insurance-exp");
			const registrationDateAlert = document.getElementById("registration-date");
			const insuranceRadioYes = document.getElementById("insuranceRadioYes");
			const insuranceRadioNo = document.getElementById("insuranceRadioNo");
			const registrationRadioYes = document.getElementById("registrationRadioYes");
			const registrationRadioNo = document.getElementById("registrationRadioNo");
			app.backButton.addEventListener("click", () => previous("/exterior-inspection"));
			app.nextButton.addEventListener("click", () => validatePage("/interior-check"));
			insuranceRadioYes.addEventListener("click", () => showDiv("insurance-div"));
			insuranceRadioNo.addEventListener("click", () => hideDiv("insurance-div"));
			registrationRadioYes.addEventListener("click", () => showDiv("registration-div"));
			registrationRadioNo.addEventListener("click", () => hideDiv("registration-div"));

			insuranceExpAlert.buttons = [
				{
					text: "Cancel",
					role: "cancel",
					id: "insurance-exp-cancel",
					handler: () => {
						consoleMsg("Alert canceled");
					},
				},
				{
					text: "OK",
					role: "confirm",
					id: "insurance-exp-ok",
					handler: () => {
						consoleMsg("Alert confirmed");
					},
				},
			];

			registrationDateAlert.buttons = [
				{
					text: "Cancel",
					role: "cancel",
					id: "registration-date-cancel",
					handler: () => {
						consoleMsg("Alert canceled");
					},
				},
				{
					text: "OK",
					role: "confirm",
					id: "registration-date-ok",
					handler: () => {
						consoleMsg("Alert confirmed");
					},
				},
			];
		});
	}
}

export class PageFour extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.pageFourContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await initializePage(4);
		});
		this.addEventListener("ionViewDidEnter", async () => {
			app.backButton.addEventListener("click", () => previous("/glovebox-check"));
			app.nextButton.addEventListener("click", () => validatePage("/road-test"));
		});
	}
}

export class PageFive extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.pageFiveContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await initializePage(5);
		});
		this.addEventListener("ionViewDidEnter", async () => {
			app.backButton.addEventListener("click", () => previous("/interior-check"));
			app.nextButton.addEventListener("click", () => validatePage("/lights-check"));
		});
	}
}

export class PageSix extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.pageSixContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await initializePage(6);
		});
		this.addEventListener("ionViewDidEnter", async () => {
			app.backButton.addEventListener("click", () => previous("/road-test"));
			app.nextButton.addEventListener("click", () => validatePage("/fluid-inspection"));
		});
	}
}

export class PageSeven extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.pageSevenContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await initializePage(7);
		});
		this.addEventListener("ionViewDidEnter", async () => {
			app.backButton.addEventListener("click", () => previous("/lights-check"));
			app.nextButton.addEventListener("click", () => validatePage("/tire-inspection"));
		});
	}
}

export class PageEight extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.pageEightContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await initializePage(8);
		});
		this.addEventListener("ionViewDidEnter", async () => {
			app.backButton.addEventListener("click", () => previous("/fluid-inspection"));
			app.nextButton.addEventListener("click", () => validatePage("/signature"));
		});
	}
}

export class Signature extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.signatureContent;
		this.addEventListener("ionViewWillEnter", async () => {
			await setMenu("menu-signature");
			await setHeader("header-signature", inspector);
			await setFooter("footer-signature");
			await updateOfflineStatus("offline-signature");
			async function setPrintedName(inspector) {
				const printedNameElement = document.getElementById("printed-name");
				consoleMsg("Printed name element = ", printedNameElement);
				const elementLabel = "Printed name: " + inspector;
				printedNameElement.innerHTML = elementLabel;
			}
			await setPrintedName(inspector);
		});
		this.addEventListener("ionViewDidEnter", async () => {
			const refresher = this.querySelector("ion-refresher");
			if (refresher) {
				refresher.addEventListener("ionRefresh", handleRefresh);
			}
			app.currentPage = "page-signature";
			consoleMsg("Signature page entered");
			const submit = document.getElementById("submitButton");
			const wrapper = document.getElementById("signature-pad");
			const clearButton = wrapper.querySelector("[data-action=clear]");
			const savePNGButton = wrapper.querySelector("[data-action=save-png]");
			const canvas = wrapper.querySelector("canvas");
			const signaturePad = new SignaturePad(canvas, {
				// It's Necessary to use an opaque color when saving image as JPEG;
				// this option can be omitted if only saving as PNG or SVG
				backgroundColor: "rgb(255, 255, 255)",
			});
			const signatureAlert = document.getElementById("signature-alert");
			const missingDataAlert = document.getElementById("missing-data-alert");
			/* message=*/
			signatureAlert.buttons = [
				{
					text: "OK",

					handler: () => {
						signatureAlert.dismiss();
					},
				},
			];
			clearButton.addEventListener("click", function (event) {
				signaturePad.clear();
			});
			missingDataAlert.message = `You have not completed entering all required data. Please ensure you have 
			answered all required questions on each page of the inspection form.`;
			missingDataAlert.buttons = [
				{
					text: "OK",
					handler: () => {
						missingDataAlert.dismiss();
					},
				},
			];

			const page1 = await store.get("page1");
			const vehicleInfo = page1["page1"]["vehicle-info-table"];
			const contactInfo = page1["page1"]["contact-info-table"];
			const loanInfo = page1["page1"]["loan-info-table"];

			consoleMsg(vehicleInfo);
			consoleMsg(contactInfo);
			consoleMsg(loanInfo);

			submit.addEventListener("click", async function (event) {
				//await processData();
				let data = {};
				if (signaturePad.isEmpty()) {
					signatureAlert.present();
					//alert("Please provide a signature first.");
				} else {
					if (app.validator.getPageCount() !== 8) {
						missingDataAlert.present();
						return;
					} else {
						const signature = signaturePad.toDataURL();
						consoleMsg(app.validator.getPageCount());
						Object.keys(localStorage).forEach((key) => {
							const value = localStorage.getItem(key);
							data[key] = value;
							//consoleMsg(key, value);
						});
						store.set("signature", signature);
						generateInspectionPDF(vehicleInfo);
					}
				}
			});

			// TEMPORAY: SUBMIT FORM WITHOUT VALIDATION CHECKS

			/*
			submit.addEventListener("click", async function (event) {
				//await processData();
				let problems = {};
				if (signaturePad.isEmpty()) {
					alert("Please provide a signature first.");
				} else {
					const signature = signaturePad.toDataURL();
					consoleMsg(app.validator.getPageCount());
					store.set("signature", signature);
					//globalProblems = problems;
					generateInspectionPDF(details);
				}
			});*/

			// On mobile devices it might make more sense to listen to orientation change,
			// rather than window resize events.
			window.onresize = () => resizeCanvas(canvas, signaturePad);

			window.dispatchEvent(new Event("resize"));

			//window.addEventListener("resize", resizeCanvas(canvas, signaturePad));
			//resizeCanvas(canvas, signaturePad);

			const myBack = document.getElementById("app.backButton9");
			const mySignature = document.getElementById("signature-pad");
			myBack.removeEventListener("click", previous);
			myBack.addEventListener("click", () => previous("/tire-inspection"));

			function reloadTest() {
				consoleMsg("WINDOW RELOADED...");
			}

			// Define the resize handler
			const resizeHandler = () => {
				console.log("Window resized!");
				// Your resize handling code here
			};

			// Attach the resize event listener
			window.addEventListener("resize", resizeHandler);
		});
		this.addEventListener("ionViewWillLeave", () => {
			consoleMsg("Signature view left");
		});
	}
}

export class AppSettings extends HTMLElement {
	connectedCallback() {
		this.innerHTML = app.appSettingsContent;
		this.addEventListener("ionViewDidEnter", () => {
			app.currentPage = "settings";
			consoleMsg("App Settings entered");
			const refresher = this.querySelector("ion-refresher");
			if (refresher) {
				refresher.addEventListener("ionRefresh", handleRefresh);
			}
			// Query for the toggle that is used to change between palettes
			/*const toggle = document.getElementById("paletteToggle");
			const ripple = document.getElementById("rippleToggle");
			const ios = document.getElementById("iosToggle");*/
			const clearStorageAlert = document.getElementById("clearStorageAlert");
			// Use matchMedia to check the user preference
			//const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

			clearStorageAlert.buttons = [
				{
					text: "OK",
					handler: () => {
						clearStorage();
						clearStorageAlert.dismiss();
					},
				},
			];

			// Listen for the toggle check/uncheck to toggle the dark palette
			/*toggle.addEventListener("ionChange", (ev) => {
				consoleMsg("Toggle checked");
				toggleDarkPalette(ev.detail.checked);
			});*/

			// Listen for changes to the prefers-color-scheme media query
			//prefersDark.addEventListener('change', (mediaQuery) => initializeDarkPalette(mediaQuery.matches));

			// Called by the media query to check/uncheck the toggle
			function checkToggle(shouldCheck) {
				toggle.checked = shouldCheck;
			}

			// Check/uncheck the toggle and update the palette based on isDark
			function initializeDarkPalette(isDark) {
				if (app.currentPage === "settings") {
					const toggle = document.getElementById("paletteToggle");
					toggle.checked = isDark;
				}
				toggleDarkPalette(isDark);
			}

			// Add or remove the "ion-palette-dark" class on the html element
			function toggleDarkPalette(shouldAdd) {
				const logo = document.getElementById("logo");
				consoleMsg("LOGO: ", logo);
				document.documentElement.classList.toggle("ion-palette-dark", shouldAdd);
				logo.src = shouldAdd ? "assets/img/logo-dark-186.png" : "assets/img/logo-186.png";
			}

			//initializeDarkPalette(prefersDark.matches);

			// Toggle ripple effect
			/*ripple.addEventListener("ionChange", (ev) => {
				let rippleState = window.Ionic.config.rippleEffect;
				if (ripple.checked) {
					rippleState = true;
				} else {
					rippleState = false;
				}
				consoleMsg(rippleState);
			});*/

			// Toggle iOS mode
			/*ios.addEventListener("ionChange", (ev) => {
				let iosState = window.Ionic.mode;
				if (ios.checked) {
					iosState = "ios";
					consoleMsg(iosState);
				} else {
					iosState = "md";
					consoleMsg(iosState);
				}
			});*/

			/*const toggleMode = document.getElementById("iosToggle");
			toggleMode.addEventListener("ionChange", (event) => {
				const mode = event.detail.checked ? "ios" : "md";
				document.documentElement.setAttribute("mode", mode);
			});*/

			const clearStorageButton = document.getElementById("clearStorageButton");
			clearStorageButton.removeEventListener("click", clearStorage);
			clearStorageButton.addEventListener("click", () => clearStorage());

			const reloadWindowButton = document.getElementById("reloadWindowButton");
			/*reloadWindowButton.removeEventListener("click", forceReload);
			reloadWindowButton.addEventListener("click", () => forceReload());*/

			async function clearStorage() {
				clearStorageAlert.present();
				localStorage.clear();
				await store.clear();
				app.damageMarkers = {};
				app.photoData = {
					"car-front": { src: imgMapping["car-front.png"], updated: false },
					"car-driver-side": { src: imgMapping["car-driver-side.png"], updated: false },
					"car-passenger-side": { src: imgMapping["car-passenger-side.png"], updated: false },
					"car-back": { src: imgMapping["car-back.png"], updated: false },
					"car-interior-front": { src: imgMapping["car-interior-front.png"], updated: false },
					"car-interior-rear": { src: imgMapping["car-interior-rear.png"], updated: false },
					"car-vin": { src: imgMapping["car-vin-label.png"], updated: false },
					"car-top": { src: imgMapping["car-top.png"], updated: false },
					"car-driver-profile": { src: imgMapping["car-driver-profile.png"], updated: false },
					"car-passenger-profile": { src: imgMapping["car-passenger-profile.png"], updated: false },
					"car-trunk": { src: imgMapping["car-trunk.png"], updated: false },
					"car-odometer": { src: imgMapping["car-odometer.png"], updated: false },
				};
			}

			const checkPageRadios = document.getElementById("checkRadiosToggle");
			checkPageRadios.addEventListener("ionChange", (event) => {
				const mode = event.detail.checked;
				if (mode) {
					app.appOptions.checkRadios = true;
				} else {
					app.appOptions.checkRadios = false;
				}
				consoleMsg("Check radios:", app.appOptions.checkRadios);
			});
		});
	}
}
