/* 
********************************************************
Data and photo creation and storage functions 
********************************************************
*/

export { isLocalStorageAvailable, savePhoto, saveCarPhoto, restorePicFromDb, deletePhoto, storeTableData };
import { consoleMsg } from "./utility";

function isLocalStorageAvailable() {
	// Example provided in API book does not work.  This is the recommended approach from
	// MDN Developer Network
	try {
		var x = "__storage_test__";
		window.localStorage.setItem(x, x);
		window.localStorage.removeItem(x);
		return true;
	} catch (e) {
		return false;
	}
}

// prettier-ignore
async function storeTableData(dataObject) {
  const storageObject = { 'page1': {} }
  for (const [key, value] of Object.entries(dataObject)) {
    consoleMsg('key: ', key + ' value: ', value);
    storageObject['page1'][key] = value;
    //await store.set(myData[key], myData.value);
  }
  consoleMsg('Storage object: ', storageObject);
  await store.set('page1', storageObject);
}

/*Dual-purpose function to save photo to IndexedDB and display in app.  If flag is set to true, it means
that the function context is to restore photos from Ionic Storage and display in app.  If flag is false, it means
that a photo has been taken and is being rendered to the current page.*/

async function savePhoto(imgRow, img, flag = false) {
	consoleMsg("imgRow = ", imgRow);
	consoleMsg("Flag = ", flag);
	const i = new Image();
	// Crreate an ion-col element as a wrapper to hold the photo
	const component = document.createElement("ion-col");
	const numberOfPhotos = imgRow.children.length;
	const photoId = String(numberOfPhotos + 1);
	// Set size to 3 of available 12 columns (25% column width)
	component.setAttribute("size", "3");
	i.src = img;
	i.id = "photo" + photoId + "-" + imgRow.id;
	i.classList.add("landscape-img");
	imgRow.append(component);
	component.append(i);
	setTimeout(() => {
		enableDeleteButton(component);
	}, 200);
	// If flag is false, store the photo in Ionic Storage
	if (!flag) {
		await storePicDb(i, imgRow);
	}
}

function saveCarPhoto(key, img) {
	consoleMsg("Image to store = ", img);
	consoleMsg("Element = ", key);
	const record = app.photoData[key];
	consoleMsg("Car photo record = ", record);
	const div = document.getElementById(key);
	const errorDiv = document.getElementById(key + "-error");
	const icon = document.getElementById(key + "-valid-icon");

	record.src = img;
	record.updated = true;

	const element = document.getElementById(key);
	const image = element.firstElementChild;
	image.src = "";
	setTimeout(async () => {
		image.src = app.photoData[key].src;
		storePicDb(image);
		icon.classList.remove("hidden");
		div.classList.remove("hidden");
		errorDiv.classList.add("hidden");
	}, 50);
}

async function storePicDb(img, imgRow) {
	try {
		let photo = {};
		let photoId;

		// Convert img.src (URL) to Blob
		const response = await fetch(img.src);
		const blob = await response.blob();

		// Convert Blob to Base64 (await the promise here)
		const base64Data = await blobToBase64(blob);
		let imgRatio = img.naturalHeight / img.naturalWidth;
		consoleMsg("Returned ratio = ", imgRatio);

		// Pass references to the photo and its aspect ratio to the photo object
		if (app.currentPage === "page2") {
			photoId = img.id;
			photo = { id: photoId, ratio: imgRatio, row: imgRow.id, photo: base64Data };
		} else {
			consoleMsg("Image = ", img);
			// Since ion-img elements don't have width or height, we have to pass in the proper aspect ratio
			imgRatio = 3 / 4;
			photoId = img.getAttribute("name");
			photo = { id: photoId, ratio: imgRatio, photo: base64Data };
		}
		// Store the Base64 encoded image and its aspect ratio
		await store.set(photoId, photo);
		consoleMsg("Image stored in IndexedDB as Base64");
	} catch (error) {
		console.error("Error storing image:", error);
	}
}

async function getImgRatio(img) {
	setTimeout(async () => {
		const imgRatio = img.naturalHeight / img.naturalWidth;
		consoleMsg("Ratio = ", imgRatio);
		return imgRatio;
	}, 100);
}

async function restorePicFromDb() {
	const keys = await store.keys(); // Get all keys from Ionic Storage
	// Set flag to true to prevent further storage of images in savePhoto function
	const flag = true;
	const photoKeys = keys.filter((key) => key.includes("photo"));

	for (const key of photoKeys) {
		const currentPhoto = await store.get(key);
		const subKey = currentPhoto.photo;
		const row = currentPhoto.row;
		//let base64Pic = await store.get(key.subKey); // Await for each key retrieval
		let base64Pic = subKey;
		const imgRow = document.getElementById(row);
		if (imgRow) {
			// Check if the image for this specific key has already been added
			const existingImages = Array.from(imgRow.children).map((child) => child.firstElementChild.src);

			if (!existingImages.includes(URL.createObjectURL(base64ToBlob(base64Pic, "image/png")))) {
				// Only add the image if it’s not already present in the row
				// Convert Base64 to Blob
				const blob = base64ToBlob(base64Pic, "image/png");

				// Convert the Blob back to an object URL for the image
				const objectURL = URL.createObjectURL(blob);

				// Use the objectURL to restore the image
				await savePhoto(imgRow, objectURL, flag); // Passing the object URL instead of the blob
			} else {
				console.error(`Row with ID ${imgRowId} not found.`);
			}
		}
	}
}

// Function to convert Blob to Base64 prior to storage

function blobToBase64(blob) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onloadend = () => {
			resolve(reader.result.split(",")[1]); // Removing the "data:*/*;base64," part
		};
		reader.onerror = reject;
		reader.readAsDataURL(blob);
	});
}

// Function to convert Base64 to Blob for image display

function base64ToBlob(base64, mimeType) {
	const byteCharacters = atob(base64);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	return new Blob([byteArray], { type: mimeType });
}

function enableDeleteButton(col) {
	const deleteButton = document.getElementById("deletePhotoButton" + col.parentElement.id.substr(-1));
	deleteButton.removeAttribute("disabled");
}

async function deletePhoto() {
	const photoRow = document.getElementById("photoRow" + this.id.substr(-1));
	const deleteButton = document.getElementById("deletePhotoButton" + this.id.substr(-1));
	consoleMsg("PHOTO ROW = ", photoRow);
	// Get all keys from the store
	const keys = await store.keys();
	let lastPhoto;
	consoleMsg(photoRow.id);
	if (photoRow.children.length > 0) {
		lastPhoto = photoRow.lastChild.children[0];
		// Iterate over the keys using a for...of loop
		for (const key of keys) {
			//if (key.includes("photo")) {
			if (key === lastPhoto.id) {
				const photo = await store.get(key);
				consoleMsg("Last child ID = ", lastPhoto.id);
				await store.remove(key);
			}
		}
		photoRow.removeChild(photoRow.lastChild);
		setTimeout(() => {
			disableDeletePhotoButton(deleteButton, photoRow);
		}, 100);
	}
}

function disableDeletePhotoButton(button, photoRow) {
	if (photoRow.children.length === 0) {
		consoleMsg("Disabling Delete button...");
		button.setAttribute("disabled", "");
	}
}
